:root {
    --color-black: #212121;
    --color-white: #ffffff;
    --color-monochrome: #424242;
    --color-lighter-monochrome: #757575;
    --color-red: #d9221c;
    --color-blue: #0074bd;
    --color-darker-blue: #0064A4;
    --color-green: #2b8000;
    --color-orange: #ad5f00;
    --color-grey: #e0e0e0;
    --color-blue-background: #f0f7fb;
    --color-blue-background-darker: #d5e1ed;
    --color-blue-border: #0076bd;
    --color-lighter-blue-background: #f7f9fd;
    --color-lighter-blue-border: #b2d8f0;
    --color-darker-red: #ad1b16;
    --color-darker-violet: #751056;
    --color-light-violet: #ae20ae;
    --color-light-orange: #ffd4c1;
    --color-lighter-orange: #FCF8F3;
    --color-menu-grey: #757575;

    --color-background: #f7f7f7;
    --color-error-background: rgba(217, 34, 28, 0.05);
    --color-box-shadow: rgba(0, 0, 0, 0.04);
    --color-border: #9e9e9e;
    --color-border-light: rgba(0, 0, 0, 0.12);
    --color-selected: #eff6fb;
    --color-backdrop: rgba(0, 0, 0, 0.5);
    --color-backdrop-darker: rgba(0, 0, 0, 0.8);
    --color-hover: #f3f3f3;
    --color-grey-text: #595959;
    --color-lighter-grey: #d8d8d8;


    //trial countdown colors
    --color-info: #004b7b;
    --color-info-background: rgba(0, 100, 164, 0.08);
    --color-warning: #ad5f00;
    --color-warning-background: #fffaf0;
    --color-critical: #821410;
    --color-critical-background:#fff0ed;

    // notification types colors
    --color-lighter-red: #fff0ed
}
