/* scrollbar */
*::-webkit-scrollbar {
    width: 6px !important; /* width of the entire scrollbar */
    position: fixed;
}

*::-webkit-scrollbar-track {
    background: #e0e0e0; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: #9e9e9e; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 1px solid #9e9e9e; /* creates padding around scroll thumb */
}

/* notifications bell animation */
@keyframes ring {
    0% {
        transform: rotate(35deg);
    }
    12.5% {
        transform: rotate(-30deg);
    }
    25% {
        transform: rotate(25deg);
    }
    37.5% {
        transform: rotate(-20deg);
    }
    50% {
        transform: rotate(15deg);
    }
    62.5% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* checkbox img */
.checkbox-img-unchecked {
    display: flex;
    line-height: 36px;
    padding-right: 8px;

    &:before {
        content: "";
        margin: 0 8px;
        background-image: url("../images/checkbox-unchecked.svg");
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position-x: center;
        background-position-y: center;
        width: 24px;
        height: 38px;
        cursor: pointer;
        min-width: 24px;
    }
}

.checkbox-img-checked {
    display: flex;
    line-height: 36px;
    padding-right: 8px;

    &:before {
        content: "";
        margin: 0 8px;
        background-image: url("../images/checkbox-checked.svg");
        background-repeat: no-repeat;
        background-size: 18px 18px;
        background-position-x: center;
        background-position-y: center;
        width: 24px;
        height: 38px;
        cursor: pointer;
        min-width: 24px;
    }
}

.full-height {
    height: 100%
}

.low-opacity {
    opacity: 0.3
}

html.tour {
    * {
        pointer-events: none !important;
    }

    .tour-popper, .cookies-component {
        pointer-events: all !important;

        & * {
            pointer-events: all !important;
        }
    }
}