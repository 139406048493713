/** paddings **/
.pt-16 {
  padding-top: 16px
}

.pb-16 {
  padding-bottom: 16px 
}

.pl-16 {
  padding-left: 16px 
}

.pr-16 {
  padding-right: 16px 
}

.pall-16 {
  padding: 16px 
}

.pt-8 {
  padding-top: 8px 
}

.pb-8 {
  padding-bottom: 8px 
}

.pl-8 {
  padding-left: 8px 
}

.pr-8 {
  padding-right: 8px 
}

.pall-8 {
  padding: 8px 
}

.pt-24 {
  padding-top: 24px 
}

.pb-24 {
  padding-bottom: 24px
}

.pl-24 {
  padding-left: 24px 
}

.pr-24 {
  padding-right: 24px 
}

.pall-24 {
  padding: 24px 
}

.pt-48 {
  padding-top: 48px 
}

.pt-56 {
  padding-top: 56px
}

/** margins **/
.mt-56 {
  margin-top: 56px
}

.ml-56 {
  margin-left: 56px
}

.mr-56 {
  margin-right: 56px
}

.mt-32 {
  margin-top: 32px
}

.mb-32 {
  margin-bottom: 32px
}

.ml-32 {
  margin-left: 32px
}

.mr-32 {
  margin-right: 32px
}

.mb-24 {
  margin-bottom: 24px 
}

.mt-24 {
  margin-top: 24px
}

.ml-24 {
  margin-left: 24px
}

.mr-24 {
  margin-right: 24px
}

.mt-16 {
  margin-top: 16px 
}

.mb-16 {
  margin-bottom: 16px 
}

.ml-16 {
  margin-left: 16px 
}

.mr-16 {
  margin-right: 16px
}

.mall-16 {
  margin: 16px 
}

.mt-8 {
  margin-top: 8px 
}

.mb-8 {
  margin-bottom: 8px 
}

.ml-8 {
  margin-left: 8px 
}

.mr-8 {
  margin-right: 8px
}

.mall-8 {
  margin: 8px 
}

.pall-0 {
  padding: 0px !important;
}

