/* text-fontSize */
.text-8 {
  font-size: 8px;
  line-height: 8px;
}

.text-13 {
  font-size: 13px;
  line-height: 17px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

.text-16 {
  font-size: 16px;
  line-height: 20px;
}

.text-18 {
  font-size: 18px;
  line-height: 22px;
}

.text-20 {
  font-size: 20px;
  line-height: 24px;
}

.text-24 {
  font-size: 24px;
  line-height: 28px;
}

.text-32 {
  font-size: 32px;
  line-height: 36px;
}

/* text-fontFamily */
.text-regular {
  font-family: var(--font-regular);
}

.text-semiBold {
  font-family: var(--font-semi-bold);
}

.text-bold {
  font-family: var(--font-bold);
}

.text-latoRegular {
  font-family: var(--font-lato-regular);
}

.text-latoBold {
  font-family: var(--font-bolder);
}