.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
}

.flex-row-start {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
}

.flex-column-center {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.flex-column-start {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
}

.flex-column-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.flex-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
