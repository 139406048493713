.wysiwyig-content {
    hr {
        margin: 40px 0;
        border: none;
        border-top: 1px solid var(--color-grey);
    }

    h1 {
        font-family: var(--font-bold);
        font-size: 40px;
        line-height: 50px;
        margin: 0 0 32px 0;
    }

    h2 {
        font-family: var(--font-bold);
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 28px 0;
    }

    h3 {
        font-family: var(--font-bold);
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 24px 0;
    }

    h4 {
        font-family: var(--font-bold);
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 16px 0;
    }

    h5 {
        font-family: var(--font-bold);
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 8px 0;
    }

    h6 {
        font-family: var(--font-semi-bold);
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 8px 0;
    }

    p {
        margin-bottom: 8px;
    }

    blockquote {
        padding: 8px 8px 8px 36px;
        line-height: 20px;
        color: var(--color-black);
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        font-family: var(--font-semi-bold);
        background: white;
        margin: 0 0 32px 0;
        border-radius: 6px;
        border: 1px solid var(--color-grey);
    }

    blockquote:before {
        content: "“";
        float: left;
        margin-left: -31px;
        font-size: 40px;
        opacity: 0.3;
        margin-top: 8px;
    }

    pre {
        background: white;
        margin: 0 0 32px 0;
        padding: 8px;
        border-radius: 6px;
        border: 1px solid var(--color-grey);
    }

    .block-notification {
        border-radius: 4px;
        color: var(--color-black);
        padding: 16px 16px 16px 48px;
        margin-bottom: 40px;
    }

    .block-notification.info {
        background: url("../images/informative-notification.svg") no-repeat 16px 18px #F5F7FC;
        border: solid 1px var(--color-blue);
    }

    .block-notification.warning {
        background: url("../images/warning-notification.svg") no-repeat 16px 18px #FCF8F3;
        border: solid 1px var(--color-orange);
    }

    .block-notification.critical {
        background: url("../images/critical-notification.svg") no-repeat 16px 18px #fff0ed;
        border: solid 1px var(--color-red);
    }

    .block-notification.regular {
        background: white;
        padding: 16px;
        border: solid 1px transparent;
    }

    inline {
        display: inline-block;
        align-items: center;

        div:first-of-type {
            display: inline-block;
            margin-right: 3px;
            height: 16px;
        }

        div:last-of-type {
            cursor: pointer;
            text-decoration: underline;
            color: var(--color-blue);
            display: inline-block;
            padding-top: 0;
        }

        svg {
            width: 24px;
            height: 24px;
            margin-bottom: -6px;
        }
    }

    .item-card {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        width: 50%;
        padding-right: 16px;
        padding-bottom: 16px;

        & > div:first-of-type {
            width: 100%;
        }

        .card-component.linkCard.small {
            height: 254px;
            min-height: 254px;
        }

        .card-component {
            width: unset;
        }
    }

    ul, ol {
        padding-left: 17px;

        li {
            margin-bottom: 0;
            line-height: 22px;
            padding-left: 5px;
        }
    }

    ul {
        list-style: disc;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    ol {
        list-style: decimal;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }

    strong {
        font-family: var(--font-bold);
    }

    em {
        font-style: italic;
    }

    p {
        font-family: var(--font-regular);
        font-size: 16px;
        line-height: 22px;
        color: var(--color-black);
    }

    mjx-math {
        max-height: 100%;
        max-width: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: baseline;

        > * {
            margin-bottom: 8px;
        }
    }

    .faq-body {
        p {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
    }

    table, tr, th, td {
        border-collapse: collapse;
        padding: 8px;
        border: 1px;
        border-color: unset;

        img {
            max-width: unset;
            max-height: unset;
        }
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
    }

    .tile-content-container {
        font-family: 'sourceSansProRegular', sans-serif;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        width: 716px;
    }

    .tile-content-items {
        box-sizing: border-box;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        width: 350px;
        height: 310px;
        padding: 0px;
    }

    .tile-content-items img{
        width: 350px;
        height: 128px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: #f0f0f0;
        object-fit: cover;
        display: block;
    }

    .tile-content-items .tile-content-text {
        width: 100%;
        height: 145px;
        padding: 8px 8px 0px 8px;
    }

    .tile-content-items .tile-content-link {
        padding-left: 8px;
    }

    .tile-content-items .tile-content-link a {
        text-decoration: none;
        font-weight: 600;
        cursor: pointer;
    }

    @media (max-width: 850px) {
        .tile-content-container {
            width: 350px;
        }
    }
}
