.date-range-picker {
    position: absolute;
    box-shadow: 1px 1px 4px var(--color-box-shadow);
    z-index: 500;
    border: 1px solid var(--color-border-light);

    .rdrStaticRange {
        color: var(--color-blue) !important;
    }

    .rdrStaticRangeSelected {
        color: var(--color-blue) !important;
    }

    .rdrStartEdge {
        color: var(--color-blue) !important;
    }

    .rdrInRange {
        color: var(--color-blue) !important;
    }

    .rdrEndEdge {
        color: var(--color-blue) !important;
    }

    .rdrDayStartPreview {
        color: var(--color-blue) !important;
    }

    .rdrDayInPreview {
        color: var(--color-blue) !important;
    }

    .rdrDayEndPreview {
        color: var(--color-blue) !important;
    }

    .rdrDayToday {
        ::after {
            background: var(--color-blue) !important;
        }
    }

    .rdrDateDisplay {
        color: var(--color-blue) !important;
    }
}